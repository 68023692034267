import { useApolloClient } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Button, Input } from 'components'
import { PermissionsInput, useToasts } from 'containers'
import {
  useAbility,
  UserInAppContext,
  UserInBankContext,
} from 'containers/Layout'
import React, { useContext, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  AppBySlugDocument,
  AppBySlugQuery,
  BankBySlugDocument,
  BankBySlugQuery,
  InvitationType,
  OrgMetaDocument,
  OrgMetaQuery,
  ToastKind,
  useCreateInvitationMutation,
  useUsersInAppQuery,
  useUsersInBankQuery,
  useUsersInOrgQuery,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { Router } from 'utils/router'
import { patterns } from 'utils/utils'

type InvitationFormData = {
  permissions: string[]
  emails: { email: string }[]
}

interface InvitationFormProps {
  type: 'app' | 'bank' | 'organization'
  slug: string
}

export const InvitationForm: React.FC<InvitationFormProps> = ({
  type,
  slug,
}) => {
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
    formState: { errors },
  } = useForm<InvitationFormData>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      emails: [{ email: '' }],
    },
  })

  const {
    fields: emailFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'emails',
  })

  const { addToast } = useToasts()
  const apollo = useApolloClient()
  const ability = useAbility()
  const userInAppContext = useContext(UserInAppContext)
  const userInBankContext = useContext(UserInBankContext)
  const id = type === 'app' ? userInAppContext?.id : userInBankContext?.id
  const permissions = ability.rules
    .filter((rule) => rule.conditions?.id === id)
    .map((rule) => (rule as any).action)

  const [createInvitation] = useCreateInvitationMutation()
  const { refetch: refetchAppInvitations } = useUsersInAppQuery({
    variables: { slug },
    skip: type !== 'app',
  })
  const { refetch: refetchBankInvitations } = useUsersInBankQuery({
    variables: { slug },
    skip: type !== 'bank',
  })
  const { refetch: refetchOrgInvitations } = useUsersInOrgQuery({
    variables: { slug },
    skip: type !== 'organization',
  })

  useEffect(() => {
    register({ name: 'permissions' })
  }, [register])

  const submitForm = useAsyncCallback(
    handleSubmit(async ({ emails, permissions }) => {
      let id: string | undefined
      if (type === 'app') {
        const res = await apollo.query<AppBySlugQuery>({
          query: AppBySlugDocument,
          variables: { slug },
        })
        id = res.data?.appBySlug?.id
      }

      if (type === 'bank') {
        const res = await apollo.query<BankBySlugQuery>({
          query: BankBySlugDocument,
          variables: { slug },
        })
        id = res.data?.bankBySlug?.id
      }

      if (type === 'organization') {
        const res = await apollo.query<OrgMetaQuery>({
          query: OrgMetaDocument,
          variables: { slug },
        })
        id = res.data?.organisationBySlug?.id
      }

      if (!id) {
        throw 'Invalid app/bank/organization ID'
      }

      const inv = await createInvitation({
        variables: {
          invitation: {
            emails: emails.map(({ email }) => email),
            permissions,
            type:
              type === 'app'
                ? InvitationType.App
                : type === 'bank'
                ? InvitationType.Bank
                : InvitationType.Organisation,
            objectId: id,
          },
        },
      })

      if (inv.errors) {
        throw inv.errors[0]
      }

      type === 'app' && (await refetchAppInvitations())
      type === 'bank' && (await refetchBankInvitations())
      type === 'organization' && (await refetchOrgInvitations())

      addToast({
        kind: ToastKind.Success,
        content: t`users.add.userInviteSuccess`,
      })

      Router.pushRoute(`/${type}s/${slug}/users`)
    }),
    [
      apollo,
      slug,
      createInvitation,
      addToast,
      handleSubmit,
      refetchAppInvitations,
      refetchBankInvitations,
    ]
  )

  const handlePermissionChange = (permissions: string[]) =>
    setValue('permissions', permissions, { shouldDirty: true })

  return (
    <form onSubmit={submitForm}>
      <h2 className="heading-h4 mb-16">
        1.&nbsp;
        <Trans id="users.add.setPermissions">
          Set permissions for users being invited
        </Trans>
      </h2>
      <PermissionsInput type={type} onChange={handlePermissionChange} />
      {errors.permissions && (
        <p className="text-red-500 text-14 italic py-6">{errors.permissions}</p>
      )}

      <h2 className="heading-h4 mt-40 mb-16">
        2.&nbsp;
        <Trans id="users.add.inviteByEmail">Invite members by e-mail</Trans>
      </h2>
      <p className="mb-26">
        <Trans id="users.add.inviteMultiple">
          You can invite multiple users at the same time. Separate e-mail
          addresses by a comma.
        </Trans>
      </p>

      <div className="flex flex-col w-full gap-8">
        {emailFields.map((field, index) => (
          <div className="flex-1 flex gap-8" key={field.id}>
            <Input
              className="flex-1"
              name={`emails[${index}].email`}
              ref={register({
                required: t`form.error.required`,
                pattern: {
                  message: t`form.error.email`,
                  value: patterns.email,
                },
              })}
              defaultValue={field.email}
              label={<Trans id="common.email">Email</Trans>}
              labelPosition="top"
              errors={errors.emails?.[index]?.email}
              required
              data-cy-id="invitation-form-emails"
            />
          </div>
        ))}
      </div>

      <div className="flex gap-8 justify-end mt-8">
        {emailFields.length <= 9 && (
          <Button
            kind="secondary-blue"
            onClick={() => append({ email: '' })}
            data-cy-id="email.add-btn"
          >
            {t`users.add.anotherEmail`}
          </Button>
        )}
        {emailFields.length > 1 && (
          <Button
            kind="danger"
            onClick={() => remove(emailFields.length - 1)}
            data-cy-id="email.remove-last-btn"
          >
            {t`users.add.removeEmail`}
          </Button>
        )}
      </div>

      <div className="flex justify-end mt-36">
        <Button
          loading={formState.isSubmitting}
          disabled={!formState.isDirty}
          isFormSubmit
          data-cy-id="invitation-form-submit"
        >
          <Trans id="users.add.inviteButton">Invite members</Trans>
        </Button>
      </div>
    </form>
  )
}
