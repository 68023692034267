import { t, Trans } from '@lingui/macro'
import { Button, Input, UncontrolledToggle } from 'components'
import { useToasts } from 'containers'
import { GraphQLError } from 'graphql'
import Lock from 'icons/lock.svg'
import React from 'react'
import { useForm } from 'react-hook-form'
import {
  ToastKind,
  useProfileQuery,
  useResetUserPasswordMutation,
  useSetUserTwoStepVerificationMutation,
  useUpdateUserPasswordMutation,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'
import Spinner from 'icons/spinner.svg'

type ChangePasswordFormData = {
  oldPassword: string
  newPassword: string
  repeatNewPassword: string
}

export const ChangePasswordForm: React.FC = () => {
  const profileQuery = useProfileQuery()
  const {
    id: currentUserId,
    email: currentUserEmail,
    twoStepVerificationEnabled,
    isPasswordCredentials,
  } = profileQuery.data?.currentUser || {}

  const [updatePassword] = useUpdateUserPasswordMutation()
  const [resetUserPassword] = useResetUserPasswordMutation()
  const [
    setUserTwoStepVerification,
    { loading: isSetUserTwoStepVerificationLoading },
  ] = useSetUserTwoStepVerificationMutation()
  const { addToast } = useToasts()

  const { register, handleSubmit, formState, errors, watch, setError, reset } =
    useForm<ChangePasswordFormData>({
      defaultValues: {
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
      },
    })

  const handleTwoStepVerificationChange = async (enable: boolean) => {
    try {
      await setUserTwoStepVerification({
        variables: { enable },
      })

      if (enable) {
        addToast({
          kind: ToastKind.Success,
          content: t`profile.setTwoStepVerification.success`,
          timeout: 7000,
        })
      }
      profileQuery.refetch()
    } catch (ex: any) {
      const gqlErrors: GraphQLError[] = ex.graphQLErrors ?? []

      addToast({
        kind: ToastKind.Error,
        content: gqlErrors[0]?.extensions?.errorMessage,
      })
    }
  }

  const submitForm = useAsyncCallback(
    handleSubmit(async (data) => {
      const { repeatNewPassword, ...password } = data

      if (!currentUserId) {
        throw new Error('Invalid user ID')
      }

      if (isPasswordCredentials) {
        try {
          await updatePassword({
            variables: { userId: currentUserId, password },
          })
        } catch (ex: any) {
          const gqlErrors: GraphQLError[] = ex.graphQLErrors ?? []

          if (
            gqlErrors[0]?.extensions?.errorCode ===
            'PASSWORD_VERIFICATION_FAILED'
          ) {
            setError('oldPassword', {
              type: 'manual',
              message: t`profile.password.invalidPassword`,
            })

            addToast({
              kind: ToastKind.Error,
              content: t`profile.password.invalidPassword`,
            })
            return
          }

          if (
            gqlErrors[0]?.extensions?.errorCode === 'PASSWORD_LENGTH_TOO_SHORT'
          ) {
            addToast({
              kind: ToastKind.Error,
              content: gqlErrors[0].extensions.errorMessage,
            })
            return
          }

          throw ex
        }
      } else {
        try {
          await resetUserPassword({
            variables: {
              userId: currentUserId,
              password: {
                newPassword: data.newPassword,
              },
            },
          })
        } catch (ex: any) {
          throw ex
        }
      }

      addToast({
        kind: ToastKind.Success,
        content: t`profile.password.updateSuccessful`,
      })
      reset()
    }),
    [setError, addToast, currentUserId, updatePassword]
  )

  const newPassword = watch('newPassword')

  return (
    <>
      <div className="flex items-center mb-24">
        <Lock className="fill-primary-darker inline mr-14" height={24} />
        <h4 className="heading-h2 inline">
          <Trans id="profile.password.heading">Password</Trans>
        </h4>
      </div>
      <div className="flex flex-col-reverse sm:flex-row justify-between mb-28">
        <label
          htmlFor="twoStepVerification"
          className={
            'w-full overflow-x-hidden overflow-ellipsis mt-6 sm:mt-0 text-black-900'
          }
        >
          <Trans id="profile.setTwoStepVerification">
            Two-step verification enabled
          </Trans>
        </label>
        <div className="flex items-center space-x-10">
          {isSetUserTwoStepVerificationLoading && (
            <Spinner height={20} width={20} />
          )}
          <UncontrolledToggle
            name="twoStepVerification"
            labelPosition="left"
            data-cy-id="profile.setTwoStepVerification"
            fullWidth={false}
            disabled={isSetUserTwoStepVerificationLoading}
            onChange={() =>
              handleTwoStepVerificationChange(!twoStepVerificationEnabled)
            }
            checked={twoStepVerificationEnabled ?? false}
          />
        </div>
      </div>

      <form onSubmit={submitForm}>
        <Input
          name="username"
          defaultValue={currentUserEmail}
          type="text"
          className="hidden"
          autoComplete="username"
        />

        {isPasswordCredentials && (
          <>
            <Input
              name="oldPassword"
              autoComplete="current-password"
              type="password"
              label={<Trans id="profile.oldPassword">Old password</Trans>}
              ref={register({
                required: t`form.error.required`,
              })}
              errors={errors.oldPassword}
              data-cy-id="profile.oldPassword"
            />
            <div className="mb-24" />
          </>
        )}

        <Input
          name="newPassword"
          autoComplete="new-password"
          type="password"
          label={<Trans id="profile.newPassword">New password</Trans>}
          placeholder={t`profile.newPassword.placeholder`}
          ref={register({
            minLength: {
              value: 8,
              message: t`form.error.passwordTooShort`,
            },
          })}
          errors={errors.newPassword}
          data-cy-id="profile.newPassword"
        />

        <div className="mb-24" />
        <Input
          name="repeatNewPassword"
          autoComplete="new-password"
          type="password"
          label={
            <Trans id="profile.repeatNewPassword">Repeat new password</Trans>
          }
          placeholder={t`profile.newPassword.placeholder`}
          ref={register({
            minLength: {
              value: 8,
              message: t`form.error.passwordTooShort`,
            },
            validate: (value) =>
              value === newPassword || t`form.error.passwordsMustMatch`,
          })}
          errors={errors.repeatNewPassword}
          data-cy-id="profile.repeatNewPassword"
        />
        <div className="mb-24" />

        <Button
          loading={formState.isSubmitting}
          disabled={
            !formState.isDirty || !!Object.keys(formState.errors).length
          }
          isFormSubmit
          data-cy-id="profile.changePassword.saveChanges"
        >
          <Trans id="profile.saveChanges">Save changes</Trans>
        </Button>
      </form>
    </>
  )
}
