import { Trans } from '@lingui/macro'
import { Link, Logo, Table, Button } from 'components'
import { TableColumn } from 'components/Table/common'
import { QueryLoader } from 'containers/QueryLoader'
import { NextPage } from 'next'
import React, { createContext, useContext, useState } from 'react'
import {
  AppEnvironmentInBankEnvironmentState,
  BankConnectedAppRowFragment,
  useBankConnectedAppsBySlugQuery,
} from 'types'
import {
  AppStateIndicator,
  ReRegisterButton,
  RetryAllButton,
  RetryButton,
} from './BankConnectedAppsCommon'
import { useAsyncCallback } from 'utils/handleError'

interface BankContextType {
  slug: string
  envSlug: string
  envId: string
  refetchList: () => Promise<unknown>
}

const BankContext = createContext<BankContextType>(null!)

interface AppDetailLinkProps {
  appSlug: string
}

const AppDetailLink: React.FC<AppDetailLinkProps> = ({ appSlug }) => {
  const { slug, envSlug } = useContext(BankContext)

  return (
    <Link
      kind="text"
      to={`/banks/${slug}/environments/${envSlug}/apps/${appSlug}`}
      data-cy-id={`bank.environment.connectedApps.showAppDetail.${appSlug}`}
    >
      <Trans id="bank.environment.connectedApps.detail">App detail</Trans>
    </Link>
  )
}

interface RetryProps {
  appEnvId?: string
  retryAll?: boolean
}

const Retry: React.FC<RetryProps> = ({ appEnvId, retryAll }) => {
  const { envId: bankEnvId, refetchList } = useContext(BankContext)

  if (appEnvId) {
    return (
      <RetryButton
        appEnvId={appEnvId}
        bankEnvId={bankEnvId}
        onDone={refetchList}
      />
    )
  } else {
    if (retryAll) {
      return <RetryAllButton bankEnvId={bankEnvId} onDone={refetchList} />
    } else {
      return <ReRegisterButton bankEnvId={bankEnvId} onDone={refetchList} />
    }
  }
}

const ConnectedAppsColumns: TableColumn<BankConnectedAppRowFragment>[] = [
  {
    key: 'logo',
    width: 56,
    render: (row) => (
      <Logo media={row.appEnvironment.currentConfig?.logo_id} size={24} />
    ),
  },
  {
    key: 'application',
    width: 180,
    title: (
      <Trans id="bank.environment.connectedApps.application">Application</Trans>
    ),
    render: (row) => (
      <p className="font-semibold truncate">
        {row.appEnvironment.currentConfig?.client_name}
      </p>
    ),
  },
  {
    key: 'provider',
    width: 180,
    title: <Trans id="bank.environment.connectedApps.provider">Provider</Trans>,
    render: (row) => (
      <>{row.appEnvironment.currentConfig?.client_provider_name}</>
    ),
  },
  {
    key: 'clientId',
    width: 200,
    title: <Trans id="bank.environment.connectedApps.clientId">ClientID</Trans>,
    render: (row) => <>{row.appEnvironment.app.id}</>,
  },
  {
    key: 'status',
    width: 100,
    title: (
      <Trans id="bank.environment.connectedApps.status">Sync Status</Trans>
    ),
    render: (row) => <AppStateIndicator state={row.state} />,
  },
  {
    key: 'details',
    width: 110,
    render: (row) => <AppDetailLink appSlug={row.appEnvironment.app.slug} />,
  },
  {
    key: 'retry',
    width: 120,
    render: (row) =>
      row.state !== AppEnvironmentInBankEnvironmentState.Pending ? (
        <Retry appEnvId={row.appEnvironment.id} />
      ) : null,
  },
]

interface BankConnectedAppsListProps {
  slug: string
  envSlug: string
  envId: string
}

const APPS_PER_PAGE = 50

export const BankConnectedAppsList: NextPage<BankConnectedAppsListProps> = ({
  slug,
  envSlug,
  envId,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)
  // use network only fetch policy to refresh list when user navigates away & back
  const appsQuery = useBankConnectedAppsBySlugQuery({
    variables: { envSlug, limit: APPS_PER_PAGE, pageNumber: 1 },
    fetchPolicy: 'network-only',
  })

  const { fetchMore, data, loading: isLoading } = appsQuery
  const { nodes: apps, pageInfo } = data?.bankEnvironment?.appEnvironments || {}
  const { totalCount, nextPage } = pageInfo || {}

  const handleLoadMore = useAsyncCallback(async () => {
    if (isButtonDisabled || isLoading) return
    try {
      setIsButtonDisabled(true)
      await fetchMore({ variables: { pageNumber: nextPage } })
    } catch (error) {
      console.error('Error fetching more:', error)
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false)
      }, 2_000)
    }
  }, [fetchMore, nextPage, isButtonDisabled])

  return (
    <>
      <BankContext.Provider
        value={{ slug, envSlug, envId, refetchList: appsQuery.refetch }}
      >
        <div className="flex flex-row items-center justify-between">
          <h2 className="heading-h2 mt-30 mb-30">
            <Trans id="bank.environment.connectedApps.heading">
              Connected apps
            </Trans>
          </h2>
          {/* <div className="flex flex-row ">
            <Retry retryAll={false} />
            <Retry retryAll />
          </div> */}
        </div>

        <QueryLoader queries={[appsQuery]}>
          <div className="text-14 leading-18 text-primary-darker mb-50">
            {apps && (
              <>
                <Table columns={ConnectedAppsColumns} data={apps} />
                <div className="flex flex-col items-center mt-24">
                  {totalCount && (
                    <p className="mb-16 text-center">
                      <Trans id="bank.environment.connectedApps.results">
                        Viewing {apps.length} results of {totalCount}
                      </Trans>
                    </p>
                  )}
                  {nextPage && (
                    <Button
                      kind="primary"
                      className="text-center p-16"
                      data-cy-id="bank.environment.connectedApps.loadMore"
                      onClick={handleLoadMore}
                      disabled={isLoading || isButtonDisabled}
                      loading={isLoading}
                    >
                      <Trans id="bank.environment.connectedApps.loadMore">
                        Load more
                      </Trans>
                    </Button>
                  )}
                </div>
              </>
            )}
            {apps && !apps.length && (
              <p className="text-center mt-30">
                <Trans id="bank.environment.connectedApps.noApps">
                  There are no connected apps yet.
                </Trans>
              </p>
            )}
          </div>
        </QueryLoader>
      </BankContext.Provider>
    </>
  )
}
