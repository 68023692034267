import { t, Trans } from '@lingui/macro'
import {
  Clickable,
  Input,
  TextArea,
  Toggle,
  Tooltip,
  TooltipWithIndicator,
} from 'components'
import {
  AppConfigWarning,
  LogoDropzone,
  ProductResult,
  QueryLoader,
  ScopesTable,
  useToasts,
} from 'containers'
import { GraphQLError } from 'graphql'
import { omit } from 'lodash'
import { NextPage } from 'next'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FieldError, useForm } from 'react-hook-form'
import {
  AppEnvironmentInput,
  AppEnvironmentScope,
  AppEnvironmentScopeFragmentDoc,
  AppEnvironmentType,
  ScopeLevel,
  ToastKind,
  TokenEndpointAuthMethod,
  useAppConfigDiffQuery,
  useAppConfigQuery,
  useAppCredentialsQuery,
  useApplyAppEnvChangesMutation,
  useAppMetaQuery,
  useDiscardAppEnvChangesMutation,
  useUnblockAppEnvironmentNotificationUriMutation,
  useUpdateAppConfigMutation,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'
import {
  useDebouncedCallback,
  useFileUpload,
  useUploadFormErrorHandler,
} from 'utils/hooks'
import { Router } from 'utils/router'
import { getObjectDiff, patterns, uriMaxLength } from 'utils/utils'
import { v4 } from 'uuid'
import { AppConfigDiff } from './AppConfigDiff'
import { AppConfigError, AppConfigErrors } from './AppConfigError'
import { AuthMethodSelect, ClientTypeSelect } from './Fields'
import { RefreshTokenSelect } from './Fields/RefreshTokenSelect'
import { SetAllScopes } from 'containers/Scopes/SetAllScopes'
import RetryIcon from 'icons/retry.svg'

type AppConfigFormInputs = Omit<
  AppEnvironmentInput,
  'redirect_uris' | 'request_uris' | 'scopes' | 'post_logout_redirect_uris'
> & {
  redirect_uris: string
  request_uris: string
  post_logout_redirect_uris: string
}

const validateSectorIdentifierUri =
  (getValues: () => AppConfigFormInputs) => (value: string) => {
    try {
      const redirectUriHostnames = getValues()
        .redirect_uris.split('\n')
        .filter((str) => patterns.anySchemeUri.test(str))
        .map((str) => new URL(str || '').hostname)
      const singleHostname = new Set(redirectUriHostnames).size === 1

      return (
        !redirectUriHostnames.length ||
        singleHostname ||
        !!value ||
        t`form.error.sectorIdentifierUriRequired`
      )
    } catch (_) {
      return t`form.error.sectorIdentifierUriRequired`
    }
  }

interface AppConfigFormProps {
  slug: string
  env: AppEnvironmentType
}

export const AppConfigForm: NextPage<AppConfigFormProps> = ({ slug, env }) => {
  const {
    register,
    getValues,
    setValue,
    errors,
    reset,
    handleSubmit,
    control,
    formState,
    watch,
    setError,
    clearErrors,
  } = useForm<AppConfigFormInputs>({ reValidateMode: 'onSubmit' })

  const configQuery = useAppConfigQuery({
    variables: {
      slug,
      sandbox: env === AppEnvironmentType.Sandbox,
      production: env === AppEnvironmentType.Production,
    },
    fetchPolicy: 'network-only', // otherwise scopes sometimes don't get invalidated :(
  })
  const diffQuery = useAppConfigDiffQuery({
    variables: {
      slug,
      sandbox: env === AppEnvironmentType.Sandbox,
      production: env === AppEnvironmentType.Production,
    },
  })

  const credentialsQuery = useAppCredentialsQuery({
    variables: {
      slug,
      sandbox: env === AppEnvironmentType.Sandbox,
      production: env === AppEnvironmentType.Production,
    },
  })

  const appMetaQuery = useAppMetaQuery({
    variables: { slug },
  })

  const envSlug =
    env === AppEnvironmentType.Production ? 'production' : 'sandbox'

  const environment = configQuery.data?.appBySlug?.[envSlug]
  const appEnvId = environment?.id
  const config = environment?.updatedConfig
  const diff = diffQuery.data?.appBySlug?.[envSlug]?.pendingConfigChanges
  const activeProductIsTariff = environment?.isTariff ?? true

  useEffect(() => {
    register(
      { name: 'logo_id' },
      {
        validate: (file) =>
          !!file || !!config?.logo_id || t`form.error.required`,
      }
    )
  }, [register, config?.logo_id])

  const formInitialized = useRef(false)
  const [textAreaKey, setTextAreaKey] = useState(v4())
  useEffect(() => {
    // prevent resetting form values on every refetch
    if (formInitialized.current === false && config) {
      formInitialized.current = true
      reset({
        ...config,
        logo_id: config?.logo_id?.file.id,
        redirect_uris: config?.redirect_uris.join('\n'),
        request_uris: config?.request_uris.join('\n'),
        post_logout_redirect_uris:
          config?.post_logout_redirect_uris?.join('\n'),
      })

      // force textareas to rerender
      setTextAreaKey(v4())

      // force product picker to rerender
      config?.isTariff && setIsTariff(config?.isTariff)

      environment?.isNotificationUriBlacklisted &&
        setError('notification_uri', {})
    }
  }, [config, reset])

  const { addToast } = useToasts()
  const [updateConfig] = useUpdateAppConfigMutation()

  const handleUploadError = useUploadFormErrorHandler(
    'logo_id',
    setError,
    clearErrors
  )
  const [uploading, uploadFile] = useFileUpload(undefined, handleUploadError)
  const saveChanges = useDebouncedCallback(async () => {
    if (!appEnvId) {
      throw 'Invalid app environment ID'
    }

    const values = getValues()
    const data = {
      ...omit(getObjectDiff(values, config), ['scopes']),
      redirect_uris: values.redirect_uris.split('\n'),
      request_uris: values.request_uris?.split('\n'),
      post_logout_redirect_uris: values.post_logout_redirect_uris?.split('\n'),
    }

    await updateConfig({
      variables: { appEnvId, data },
    })
    await configQuery.refetch()
    await diffQuery.refetch()
  }, 1000)

  const handleFormChange = useCallback(
    (e: ChangeEvent<HTMLFormElement>) => {
      // scope changes are handled separately
      if (e.target.type === 'radio') {
        return
      }

      saveChanges()
    },
    [saveChanges]
  )

  const handleScopeChange = useCallback(
    async (scope: AppEnvironmentScope) => {
      if (!appEnvId) {
        throw 'Invalid app environment ID'
      }

      const data = {
        scopes: [
          {
            scopeId: scope.type.id,
            scopeLevel: scope.scopeLevel,
          },
        ],
      }

      await updateConfig({
        variables: { appEnvId, data },
        update: (proxy) => {
          // update scope level without waiting for response
          proxy.writeFragment({
            id: `AppEnvironmentScope:${scope.id}`,
            fragment: AppEnvironmentScopeFragmentDoc,
            data: {
              scopeLevel: scope.scopeLevel,
            },
          })
        },
      })
      await diffQuery.refetch()
    },
    [appEnvId, updateConfig, diffQuery]
  )

  const handleAllScopesChange = useCallback(
    async (level: ScopeLevel) => {
      if (!appEnvId) {
        throw 'Invalid app environment ID'
      }

      const data = config?.scopes
        ?.filter((scope) => scope.type.availableLevels.includes(level))
        .map((scope) => {
          return {
            scopeId: scope.type.id,
            scopeLevel: level,
          }
        })

      await updateConfig({
        variables: { appEnvId, data: { scopes: data } },
      })
      await diffQuery.refetch()
      await configQuery.refetch()
    },
    [appEnvId, updateConfig, diffQuery]
  )

  const [formError, setFormError] = useState<AppConfigErrors>(null)

  const [apply] = useApplyAppEnvChangesMutation()
  const applyChanges = useAsyncCallback(
    handleSubmit(
      async () => {
        if (!appEnvId) {
          throw 'Invalid app environment ID'
        }

        try {
          await apply({ variables: { appEnvId } })
        } catch (ex: any) {
          const gqlErrors: GraphQLError[] = ex.graphQLErrors ?? []

          setFormError(gqlErrors[0]?.extensions?.errorCode)

          if (
            gqlErrors[0]?.extensions?.errorCode ===
            'APP_ENVIRONMENT_UNSUCCESSFUL_REGISTRATION'
          ) {
            addToast({
              kind: ToastKind.Error,
              timeout: 7000,
              content: t`app.environment.config.applyFailed`,
            })
            return
          }

          if (gqlErrors[0]?.extensions?.errorCode === 'INTERNAL_SERVER_ERROR') {
            addToast({
              kind: ToastKind.Error,
              timeout: 7000,
              content: t`error.somethingWentWrong.text`,
            })
            return
          }

          if (gqlErrors[0]?.extensions?.errorCode) {
            addToast({
              kind: ToastKind.Error,
              timeout: 7000,
              content: t`app.environment.config.error.${gqlErrors[0]?.extensions?.errorCode}`,
            })
            return
          }

          setFormError('GENERIC_ERROR')
          throw ex
        }

        setFormError(null)

        await configQuery.refetch()
        await diffQuery.refetch()
        await credentialsQuery.refetch()
        await appMetaQuery.refetch()

        await Router.pushRoute(`/apps/${slug}/${envSlug}/credentials`)
        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
      },
      (errors) => {
        // find first error and scroll to it
        const error = Object.values(errors)[0] as FieldError | undefined
        if (error?.ref?.name) {
          const element = document.querySelector(
            `input[name="${error.ref.name}"]`
          )
          // scroll to parent in case element is hidden (e.g. dropzone input)
          element?.parentElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }
    ),
    [appEnvId, apply, configQuery.refetch, diffQuery.refetch, formError]
  )

  const [discard] = useDiscardAppEnvChangesMutation()
  const discardChanges = useAsyncCallback(async () => {
    if (!appEnvId) {
      throw 'Invalid app environment ID'
    }

    await discard({ variables: { appEnvId } })

    formInitialized.current = false // reset form values
    clearErrors
    await configQuery.refetch()
    await diffQuery.refetch()
  }, [appEnvId, discard, configQuery.refetch, diffQuery.refetch])

  const [unblockNotifUri] = useUnblockAppEnvironmentNotificationUriMutation()
  const handleUnblockNotifUri = useAsyncCallback(async () => {
    if (!appEnvId) {
      throw 'Invalid app environment ID'
    }

    await unblockNotifUri({ variables: { appEnvId } }).then(() => {
      addToast({
        kind: ToastKind.Success,
        timeout: 7000,
        content: t`app.environment.credentials.notification_uri.unblockSuccess`,
      })
    })

    clearErrors('notification_uri')
    await configQuery.refetch()
    await diffQuery.refetch()
  }, [appEnvId, unblockNotifUri])

  const authMethod = watch(
    'token_endpoint_auth_method',
    config?.token_endpoint_auth_method
  )
  const clientType = watch('client_type', config?.client_type)

  // trigger debounced save changes on value change inside custom Select component (which doesn't trigger form onChange on its own)
  useEffect(() => {
    if (authMethod && config?.token_endpoint_auth_method !== authMethod) {
      saveChanges()
    }
  }, [authMethod, saveChanges, config])

  useEffect(() => {
    if (clientType && config?.client_type !== clientType) {
      saveChanges()
    }
  }, [clientType, saveChanges, config])

  const encryptionEnabled = watch('encryptTokens')
  const implicitFlowEnabled = watch('implicitFlowEnabled')
  const codeFlowEnabled = watch('authorizationCodeFlowEnabled')
  const redirectUris = watch('redirect_uris')

  const validateNotificationUri = useCallback(
    (value: string) => {
      // notification_uri is required if notification.claims_updated scope is enabled
      const notifications = config?.scopes.find(
        (item) => item.type.scope === 'notification.claims_updated'
      )

      if (
        (notifications?.scopeLevel === ScopeLevel.Optional ||
          notifications?.scopeLevel === ScopeLevel.Required) &&
        !value
      ) {
        return t`app.environment.config.notification_uri.required`
      }
    },
    [config?.scopes]
  )

  const hasDifferentHostnames = (urls: string[]) => {
    // Extract hostnames from URLs
    const hostnames = urls
      ?.map((url) => {
        try {
          // Use the URL constructor for parsing URLs
          return new URL(url).hostname.replace('www.', '')
        } catch (e) {
          // Handle potential errors for invalid URLs
          console.error('Invalid URL:', url)
          return null
        }
      })
      .filter(Boolean) // Remove any null values from invalid URLs

    // Check if there's at least one different hostname
    const uniqueHostnames = new Set(hostnames)
    return uniqueHostnames.size > 1
  }

  const jwksUriRequired =
    authMethod === TokenEndpointAuthMethod.PrivateKeyJwt || encryptionEnabled

  const notificationUriRequired =
    config?.scopes &&
    config.scopes.filter(
      (scope) =>
        scope.type.scope === 'notification.claims_updated' &&
        (scope.scopeLevel === 'REQUIRED' || scope.scopeLevel === 'OPTIONAL')
    ).length > 0

  const sectorIdentifierUriRequired = hasDifferentHostnames(
    redirectUris?.split('\n')
  )

  const orgName = appMetaQuery.data?.appBySlug?.organisation?.name
  const orgIdentificationNumber =
    appMetaQuery.data?.appBySlug?.organisationIdentificationNumber
  const canChangeNameAndId =
    appMetaQuery.data?.appBySlug?.organisation?.canChangeNameAndId

  const [isTariff, setIsTariff] = useState(config?.isTariff ?? false)
  const [activeProducts, setActiveProducts] = useState<string[]>([])

  const handleTariffChange = useCallback(
    async (isTariff: boolean) => {
      if (!appEnvId) {
        throw 'Invalid app environment ID'
      }

      setIsTariff(isTariff)

      const data = {
        isTariff: isTariff,
      }

      await updateConfig({
        variables: { appEnvId, data },
      })
      await configQuery.refetch()
      await diffQuery.refetch()
    },
    [appEnvId, updateConfig, diffQuery]
  )

  return (
    <QueryLoader queries={[configQuery, diffQuery, appMetaQuery]}>
      <h2 className="heading-h2 mt-40 mb-24">
        <Trans id="app.environment.config.heading">
          General application config
        </Trans>
      </h2>
      <form
        onSubmit={applyChanges}
        onChange={handleFormChange}
        className="form-mono-font"
      >
        <LogoDropzone
          data-cy-id="app.environment.config.logo_id"
          name="logo_id"
          onSelectFile={async (file) => {
            let fileInfo
            if (file) {
              fileInfo = await uploadFile(file, 'LOGO')
            }
            setValue('logo_id', fileInfo?.fileId, { shouldDirty: false })
            saveChanges()
          }}
          currentLogo={config?.logo_id}
          label={
            <Trans id="app.environment.config.logo_id">Application Logo</Trans>
          }
          labelTooltip={t`app.environment.config.logo_id.tooltip`}
          labelPosition="left"
          descriptionPosition="right"
          errors={errors.logo_id}
          loading={uploading}
          stateful={false}
          required
        />
        <div className="h-10" />

        <Input
          containerClassName="mt-6 mb-16"
          name="client_name"
          ref={register({
            required: t`form.error.required`,
          })}
          required
          labelPosition="left"
          errors={errors.client_name}
          data-cy-id="app.environment.config.client_name"
          label={
            <Trans id="app.environment.config.client_name">
              Application Name
            </Trans>
          }
          labelTooltip={t`app.environment.config.client_name.tooltip`}
        />

        <Input
          containerClassName="mt-6 mb-16"
          name="client_provider_name"
          ref={register({
            required: t`form.error.required`,
          })}
          labelPosition="left"
          errors={errors.client_provider_name}
          data-cy-id="app.environment.config.client_provider_name"
          label={
            <Trans id="app.environment.config.client_provider_name">
              Organization Name
            </Trans>
          }
          labelTooltip={t`app.environment.config.client_provider_name.tooltip`}
          readOnly={!canChangeNameAndId}
        />

        <Input
          containerClassName="my-16"
          name="client_uri"
          ref={register({
            required: t`form.error.required`,
            pattern: {
              value: patterns.secureUrl,
              message: t`form.error.patternSecureUrl`,
            },
          })}
          required
          labelPosition="left"
          errors={errors.client_uri}
          placeholder="https://"
          data-cy-id="app.environment.config.client_uri"
          label={
            <Trans id="app.environment.config.client_uri">
              Application Website
            </Trans>
          }
          labelTooltip={t`app.environment.config.client_uri.tooltip`}
          maxLength={uriMaxLength}
        />

        <Input
          containerClassName="my-16"
          name="tos_uri"
          ref={register({
            required: t`form.error.required`,
            pattern: {
              value: patterns.secureUrl,
              message: t`form.error.patternSecureUrl`,
            },
          })}
          required
          labelPosition="left"
          errors={errors.tos_uri}
          placeholder="https://"
          data-cy-id="app.environment.config.tos_uri"
          label={
            <Trans id="app.environment.config.tos_uri">
              Terms of Service URI
            </Trans>
          }
          labelTooltip={t`app.environment.config.tos_uri.tooltip`}
          maxLength={uriMaxLength}
        />

        <Input
          containerClassName="my-16"
          name="policy_uri"
          ref={register({
            required: t`form.error.required`,
            pattern: {
              value: patterns.secureUrl,
              message: t`form.error.patternSecureUrl`,
            },
          })}
          required
          labelPosition="left"
          errors={errors.policy_uri}
          placeholder="https://"
          data-cy-id="app.environment.config.policy_uri"
          label={
            <Trans id="app.environment.config.policy_uri">
              Data Usage Policy URI
            </Trans>
          }
          labelTooltip={t`app.environment.config.policy_uri.tooltip`}
          maxLength={uriMaxLength}
        />

        <Input
          containerClassName="my-16"
          name="initiate_login_uri"
          ref={register({
            required: t`form.error.required`,
            pattern: {
              value: patterns.secureUrl,
              message: t`form.error.patternSecureUrl`,
            },
          })}
          required
          labelPosition="left"
          errors={errors.initiate_login_uri}
          placeholder="https://"
          data-cy-id="app.environment.config.initiate_login_uri"
          label={
            <Trans id="app.environment.config.initiate_login_uri">
              Initiate Login URI
            </Trans>
          }
          labelTooltip={t`app.environment.config.initiate_login_uri.tooltip`}
          maxLength={uriMaxLength}
        />

        <Input
          containerClassName="my-16"
          name="tax_number"
          ref={register({
            required:
              env === AppEnvironmentType.Production && t`form.error.required`,
            maxLength: 20,
          })}
          maxLength={20}
          labelPosition="left"
          errors={errors.tax_number}
          data-cy-id="app.environment.config.tax_number"
          label={
            <Trans id="app.environment.config.tax_number">Tax number</Trans>
          }
          labelTooltip={t`app.environment.config.tax_number.tooltip`}
          readOnly={!canChangeNameAndId}
        />

        <ClientTypeSelect
          ref={register}
          defaultValue={config?.client_type ?? undefined}
          errors={errors.client_type}
          data-cy-id="app.environment.config.client_type"
        />

        <hr className="my-24" />
        <h2 className="heading-h2 mb-24">
          <Trans id="app.environments.config.oAuth">
            Open ID Connect and OAuth2 settings
          </Trans>
        </h2>

        <TextArea
          className="my-16"
          textAreaClassName="font-mono text-14 leading-22"
          name="redirect_uris"
          ref={register({
            required: t`form.error.required`,
            validate: {
              anySchema: (value: string) =>
                value
                  .split('\n')
                  .every((line) => patterns.anySchemeUri.test(line)) ||
                t`form.error.anySchemeUri`,
              notHttp: (value: string) =>
                env === AppEnvironmentType.Sandbox
                  ? value
                      .split('\n')
                      .every((line) =>
                        patterns.httpsUrlOrLocalhost.test(line)
                      ) || t`form.error.httpsUrlOrLocalhost`
                  : value
                      .split('\n')
                      .every((line) => patterns.notHttpUrl.test(line)) ||
                    t`form.error.notHttp`,
            },
          })}
          required
          labelPosition="left"
          errors={errors.redirect_uris}
          placeholder="https://"
          data-cy-id="app.environment.config.redirect_uris"
          label={
            <Trans id="app.environment.config.redirect_uris">
              Redirect URIs
            </Trans>
          }
          labelTooltip={t`app.environment.config.redirect_uris.tooltip`}
          lineNumbers
          initialRows={config?.redirect_uris.length}
          maxLineLength={uriMaxLength}
          key={`redirect_uris_${textAreaKey}`} // forces rerender on discard changes
        />

        <Input
          containerClassName="my-16"
          name="sector_identifier_uri"
          ref={register({
            pattern: {
              value:
                env === AppEnvironmentType.Production
                  ? patterns.secureUrl
                  : patterns.url,
              message:
                env === AppEnvironmentType.Production
                  ? t`form.error.patternSecureUrl`
                  : t`form.error.patternUrl`,
            },
            validate: validateSectorIdentifierUri(getValues),
          })}
          labelPosition="left"
          errors={errors.sector_identifier_uri}
          placeholder={
            env === AppEnvironmentType.Production ? 'https://' : 'http://'
          }
          data-cy-id="app.environment.config.sector_identifier_uri"
          label={
            <Trans id="app.environment.config.sector_identifier_uri">
              Sector Identifier URI
            </Trans>
          }
          labelTooltip={t`app.environment.config.sector_identifier_uri.tooltip`}
          maxLength={uriMaxLength}
          required={sectorIdentifierUriRequired}
        />

        <div className="flex flex-row items-center my-16">
          <Input
            name="notification_uri"
            ref={register({
              pattern: {
                value:
                  env === AppEnvironmentType.Production
                    ? patterns.secureUrl
                    : patterns.url,
                message:
                  env === AppEnvironmentType.Production
                    ? t`form.error.patternSecureUrl`
                    : t`form.error.patternUrl`,
              },
              validate: validateNotificationUri,
            })}
            labelPosition="left"
            errors={errors.notification_uri}
            placeholder={
              env === AppEnvironmentType.Production ? 'https://' : 'http://'
            }
            data-cy-id="app.environment.config.notification_uri"
            label={
              <Trans id="app.environment.config.notification_uri">
                Notification URI
              </Trans>
            }
            labelTooltip={t`app.environment.config.notification_uri.tooltip`}
            maxLength={uriMaxLength}
            required={notificationUriRequired}
          />
          {environment?.isNotificationUriBlacklisted && (
            <Tooltip
              kind="dark"
              displayTip={
                <Trans
                  id={'app.environment.credentials.notification_uri.blocked'}
                />
              }
              id="notification_uri.blocked"
              className="ml-10"
            >
              <Clickable data-cy-id="" onClick={handleUnblockNotifUri}>
                <RetryIcon />
              </Clickable>
            </Tooltip>
          )}
        </div>

        <Input
          containerClassName="my-16"
          name="backchannel_logout_uri"
          ref={register({
            pattern: {
              value:
                env === AppEnvironmentType.Production
                  ? patterns.secureUrl
                  : patterns.url,
              message:
                env === AppEnvironmentType.Production
                  ? t`form.error.patternSecureUrl`
                  : t`form.error.patternUrl`,
            },
          })}
          labelPosition="left"
          errors={errors.backchannel_logout_uri}
          placeholder={
            env === AppEnvironmentType.Production ? 'https://' : 'http://'
          }
          data-cy-id="app.environment.config.backchannel_logout_uri"
          label={
            <Trans id="app.environment.config.backchannel_logout_uri">
              Backchannel Logout URI
            </Trans>
          }
          labelTooltip={t`app.environment.config.backchannel_logout_uri.tooltip`}
          maxLength={uriMaxLength}
        />

        <Input
          containerClassName="my-16"
          name="frontchannel_logout_uri"
          ref={register({
            pattern: {
              value:
                env === AppEnvironmentType.Production
                  ? patterns.secureUrl
                  : patterns.url,
              message:
                env === AppEnvironmentType.Production
                  ? t`form.error.patternSecureUrl`
                  : t`form.error.patternUrl`,
            },
          })}
          labelPosition="left"
          errors={errors.frontchannel_logout_uri}
          placeholder={
            env === AppEnvironmentType.Production ? 'https://' : 'http://'
          }
          data-cy-id="app.environment.config.frontchannel_logout_uri"
          label={
            <Trans id="app.environment.config.frontchannel_logout_uri">
              Frontchannel Logout URI
            </Trans>
          }
          labelTooltip={t`app.environment.config.frontchannel_logout_uri.tooltip`}
          maxLength={uriMaxLength}
        />

        <TextArea
          className="my-16"
          textAreaClassName="font-mono text-14 leading-22"
          name="post_logout_redirect_uris"
          ref={register({
            validate: (value: string) =>
              env === AppEnvironmentType.Sandbox
                ? value
                    .split('\n')
                    .filter(Boolean)
                    .every((line) =>
                      patterns.secureUrlOrLocalhost.test(line)
                    ) || t`form.error.patternSecureUrlOrLocalhost`
                : value
                    .split('\n')
                    .filter(Boolean)
                    .every((line) => patterns.secureUrl.test(line)) ||
                  t`form.error.patternSecureUrl`,
          })}
          labelPosition="left"
          errors={errors.post_logout_redirect_uris}
          placeholder="https://"
          data-cy-id="app.environment.config.post_logout_redirect_uris"
          label={
            <Trans id="app.environment.config.post_logout_redirect_uris">
              Post Logout Redirect URIs
            </Trans>
          }
          labelTooltip={t`app.environment.config.post_logout_redirect_uris.tooltip`}
          lineNumbers
          initialRows={config?.post_logout_redirect_uris?.length || 1}
          maxLineLength={uriMaxLength}
          key={`post_logout_redirect_uris_${textAreaKey}`} // forces rerender on discard changes
        />

        <hr className="my-24" />

        {config?.scopes && (
          <>
            {envSlug === 'production' && (
              <>
                <div className="flex items-center mb-24">
                  <h2 className="heading-h2 inline">
                    <Trans id="app.environment.config.billingSettings">
                      Billing Settings
                    </Trans>
                  </h2>
                </div>
                <ProductResult
                  onSelectTariff={handleTariffChange}
                  isTariff={isTariff}
                  scopes={config?.scopes as AppEnvironmentScope[]}
                  appId={appMetaQuery?.data?.appBySlug?.id ?? ''}
                  activeProductIsTariff={activeProductIsTariff}
                  activeProducts={activeProducts}
                />
                <hr className="my-24" />
              </>
            )}

            <div className="flex items-center mb-10">
              <h2 className="heading-h2 inline">
                <Trans id="app.environment.config.scopes">Scopes</Trans>
              </h2>
              <TooltipWithIndicator
                id="app.environment.config.scopes.tooltip"
                kind="dark"
                className="inline"
                displayTip={
                  <Trans id="app.environment.config.scopes.tooltip">
                    OAuth2 scopes that that are allowed during the OAuth2
                    authorization.
                  </Trans>
                }
              />
            </div>
            <SetAllScopes
              appScopes={config.scopes as AppEnvironmentScope[]}
              handleUpdate={handleAllScopesChange}
              isTariff={isTariff}
              envSlug={envSlug}
            />
            <ScopesTable
              appScopes={config.scopes as AppEnvironmentScope[]}
              onChange={handleScopeChange}
              enforceProduct={env === AppEnvironmentType.Production}
              isTariff={isTariff}
              envSlug={envSlug}
              setActiveProducts={setActiveProducts}
            />
          </>
        )}

        <hr className="mb-24" />
        <h2 className="heading-h2 mb-24">
          <Trans id="app.environment.config.advancedSettings">
            Advanced Settings
          </Trans>
        </h2>

        <Toggle
          className="my-16"
          name="authorizationCodeFlowEnabled"
          control={control}
          labelPosition="left"
          errors={errors.authorizationCodeFlowEnabled}
          data-cy-id="app.environment.config.authorizationCodeFlowEnabled"
          label={
            <Trans id="app.environment.config.authorizationCodeFlowEnabled">
              Authorization code flow
            </Trans>
          }
          labelTooltip={t`app.environment.config.authorizationCodeFlowEnabled.tooltip`}
          rules={{
            validate: (value) => {
              if (value && implicitFlowEnabled) {
                return t`form.error.multipleAuthFlowsEnabled`
              }

              return (
                value || implicitFlowEnabled || t`form.error.noAuthFlowEnabled`
              )
            },
          }}
        />

        {codeFlowEnabled && (
          <RefreshTokenSelect
            ref={register}
            defaultValue={config?.refreshTokenEnabled}
            errors={errors.refreshTokenEnabled}
            data-cy-id="app.environment.config.refreshTokenEnabled"
          />
        )}

        <Toggle
          className="my-16"
          name="implicitFlowEnabled"
          control={control}
          errors={errors.implicitFlowEnabled}
          data-cy-id="app.environment.config.implicitFlowEnabled"
          label={
            <Trans id="app.environment.config.implicitFlowEnabled">
              Implicit flow
            </Trans>
          }
          labelTooltip={t`app.environment.config.implicitFlowEnabled.tooltip`}
        />

        <AuthMethodSelect
          ref={register}
          defaultValue={config?.token_endpoint_auth_method ?? undefined}
          errors={errors.token_endpoint_auth_method}
          data-cy-id="app.environment.config.token_endpoint_auth_method"
        />

        <Input
          className="font-mono-form"
          name="jwks_uri"
          ref={register({
            required: jwksUriRequired
              ? t`form.error.jwksUriRequired`
              : undefined,
            pattern: {
              value: patterns.secureUrl,
              message: t`form.error.patternSecureUrl`,
            },
          })}
          labelPosition="left"
          errors={errors.jwks_uri}
          placeholder="https://"
          data-cy-id="app.environment.config.jwks_uri"
          label={<Trans id="app.environment.config.jwks_uri">JWKs URI</Trans>}
          labelTooltip={t`app.environment.config.jwks_uri.tooltip`}
          maxLength={uriMaxLength}
          required={!!jwksUriRequired}
        />

        <Toggle
          className="my-16"
          name="encryptTokens"
          control={control}
          labelPosition="left"
          errors={errors.encryptTokens}
          data-cy-id="app.environment.config.encryptTokens"
          label={
            <Trans id="app.environment.config.encryptTokens">
              Encrypt tokens
            </Trans>
          }
          labelTooltip={t`app.environment.config.encryptTokens.tooltip`}
        />

        {encryptionEnabled && (
          <Toggle
            className="my-16"
            name="ellipticTokenEncryption"
            control={control}
            labelPosition="left"
            errors={errors.ellipticTokenEncryption}
            data-cy-id="app.environment.config.ellipticTokenEncryption"
            label={
              <Trans id="app.environment.config.ellipticTokenEncryption">
                Elliptic token encryption
              </Trans>
            }
            labelTooltip={t`app.environment.config.ellipticTokenEncryption.tooltip`}
          />
        )}

        <TextArea
          className="my-16"
          textAreaClassName="font-mono text-14 leading-22"
          name="request_uris"
          ref={register({
            validate: (value: string) =>
              env === AppEnvironmentType.Sandbox
                ? value
                    .split('\n')
                    .filter(Boolean)
                    .every((line) =>
                      patterns.secureUrlOrLocalhost.test(line)
                    ) || t`form.error.patternSecureUrlOrLocalhost`
                : value
                    .split('\n')
                    .filter(Boolean)
                    .every((line) => patterns.secureUrl.test(line)) ||
                  t`form.error.patternSecureUrl`,
          })}
          labelPosition="left"
          errors={errors.request_uris}
          placeholder="https://"
          data-cy-id="app.environment.config.request_uris"
          label={
            <Trans id="app.environment.config.request_uris">Request URIs</Trans>
          }
          labelTooltip={t`app.environment.config.request_uris.tooltip`}
          lineNumbers
          initialRows={config?.request_uris.length}
          maxLineLength={uriMaxLength}
          key={`request_uris_${textAreaKey}`} // forces rerender on discard changes
        />

        {appEnvId && (
          <>
            <AppConfigDiff
              diff={diff}
              env={env}
              envState={environment?.state}
              appSlug={slug}
              submitting={formState.isSubmitting}
              onReset={discardChanges}
              configWarning={
                <AppConfigWarning
                  env={env}
                  orgName={orgName}
                  configClientProviderName={config?.client_provider_name}
                  clientProviderNameDiff={diff?.client_provider_name}
                  orgIdentificationNumber={orgIdentificationNumber}
                  configTaxNumber={config?.tax_number}
                  taxNumberDiff={diff?.tax_number}
                />
              }
            />
            <AppConfigError formError={formError} />
          </>
        )}
      </form>
    </QueryLoader>
  )
}
