import { Trans } from '@lingui/macro'
import { ToastComponent } from 'containers'
import WarningIcon from 'icons/warning.svg'
import React from 'react'
import {
  AppEnvironmentConfigDiff,
  AppEnvironmentType,
  Maybe,
  ToastKind,
} from 'types'

type AppConfigWarningProps = {
  env: AppEnvironmentType
  orgName?: string
  configClientProviderName?: Maybe<string>
  clientProviderNameDiff?: AppEnvironmentConfigDiff['client_provider_name']
  orgIdentificationNumber?: Maybe<string>
  configTaxNumber?: Maybe<string>
  taxNumberDiff: AppEnvironmentConfigDiff['tax_number']
}

export const AppConfigWarning: React.FC<AppConfigWarningProps> = ({
  env,
  orgName,
  configClientProviderName,
  clientProviderNameDiff,
  orgIdentificationNumber,
  configTaxNumber,
  taxNumberDiff,
}) => {
  // in production, show warning when company ID does not match organization
  const mostRecentTaxNumber = taxNumberDiff?.new ?? configTaxNumber
  const identificationNumberDoesNotMatch =
    env === AppEnvironmentType.Production &&
    orgIdentificationNumber !== mostRecentTaxNumber

  // in production, show warning when company name does not match organization
  const mostRecentClientProviderName =
    clientProviderNameDiff?.new ?? configClientProviderName
  const nameDoesNotMatch =
    env === AppEnvironmentType.Production &&
    orgName !== mostRecentClientProviderName

  if (orgName === undefined && env === AppEnvironmentType.Production) {
    return (
      <ToastComponent kind={ToastKind.Warning} title={false}>
        <p className="text-16">
          <WarningIcon className="inline w-18 h-18 mr-10 opacity-75 fill-current" />
          <Trans id="app.environment.config.orgNotConnected">
            To apply the changes, connect the organization to which the
            application belongs.
          </Trans>
        </p>
      </ToastComponent>
    )
  }

  return null
}
